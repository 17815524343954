import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function CategoryCardService({ logo, SetChanges }) {
  console.log("logo", logo);
  const { pathname } = useLocation();

  return (
    <div key={logo?.id} className="col pb-3 pb-md-1 col-lg-3 col-6 d-flex justify-content-center align-items-center">
      <Link
        to={`/services/${logo?.slug}`}
        style={{
          fontSize: '7px',
          width: '100%',
          height: 'auto',
          backgroundColor: pathname === `/services/${logo?.slug}` ? '' : '#474747',
          borderBottom: pathname === `/services/${logo?.slug}` ? '2px solid red' : 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => SetChanges(false)}
        className='pointer p-2 bg-white d-flex flex-column justify-content-center align-items-center'
      >
        <img src={logo?.icon} width="30px" height="30px" className='' alt="" />
        <p
          style={{
            fontSize: '7px',
            color: pathname === `/services/${logo?.slug}` ? '#DE3820' : '#474747',
            lineHeight: '13px',
            marginTop: '5px',
          }}
          className='categories-text text-center d-block'
        >
          {logo?.name}
        </p>
      </Link>
    </div>
  );
}

export default CategoryCardService;
