import { useContext, useEffect, useState } from "react";
import ChangeCar from "./change-car/ChangeCar";
import SelectServices from "./select-services/SelectServices";
import { AuthContext } from "../../AuthProvider";
import { addCarContext } from "../../context/AddCarProvider";
import Loader from "../../Loader";
import Toast from "../../Tost";
import Slider from "react-slick";
import Axios from "../../Axios";
import FrequentalyAskQuestions from "../homepage/frequentaly-ask-questions/FrequentalyAskQuestions";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ServiceCard from "../../components/ServiceCard";
import CategoryCardService from "../../components/CategoryCardService";

function Services() {
  const { userToken } = useContext(AuthContext);
  const [allServisesData, setAllServicesData] = useState([]);
  const { handleGetCars, loading, userCarDetails } = useContext(addCarContext);
  const [defaultCar, setDefaultCar] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [faqsData, setFaqsData] = useState([]);
  const [categoriesFQS, setCategoriesFQS] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState();
  const [changes, SetChanges] = useState(true);

  // const getAllServices = async (userToken) => {
  //   if (userToken) {.
  //     setIsLoading(true);
  //     try {
  //       const response = await Axios.get("/get_products", {
  //         headers: {
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       });
  //       if (response.status === 200) {
  //         const data = response?.data;
  //         // console.log("servicesd..",data);
  //         setAllServicesData(data);
  //         // Toast(data.message,response.status)
  //       }
  //     } catch (err) {
  //       const error = err?.response?.data;
  //       // Toast(error?.message)
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   } else {
  //     setIsLoading(true);
  //     try {
  //       const response = await Axios.get("/get_all_products");
  //       if (response.status === 200) {
  //         const data = response?.data;
  //         //  console.log("servicesd..",data);
  //         setAllServicesData(data?.products);
  //         // Toast(data.message,response.status)
  //       }
  //     } catch (err) {
  //       const error = err?.response?.data;
  //       //  Toast(error?.message)
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const handleFAQS = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get("/service_faqs");
      if (response?.status === 200) {
        const data = response?.data;
        setFaqsData(data?.faqs);
        //   console.log("faqs..",data);
      }
    } catch (err) {
      const error = err?.response?.data
       Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFAQS();
  }, []);

  const getAllServices = async () => {
    if (userToken) {
      setIsLoading(true);
      try {
        const response = await Axios("/get_services", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            cat_id: `${id}`,
          },
        });
        if (response?.status === 200) {
          const data = response?.data;
          console.log("servicesd..", data);

          setAllServicesData(data?.products);
          setCategoriesFQS(data?.faqs);
          Toast(data.message, response.status);
        }
      } catch (err) {
        const error = err?.response?.data;
        //  Toast(error?.message)
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      try {
        const response = await Axios("/get_all_products", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            cat_id: `${id}`,
          },
        });
        if (response?.status === 200) {
          const data = response?.data;
          // console.log("servicesd..",data);
          setAllServicesData(data?.products);
          setCategoriesFQS(data?.faqs);
          Toast(data.message, response.status);
        }
      } catch (err) {
        const error = err?.response?.data;
        Toast(error?.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllServices();
  }, [id]);

  const getDefaultCar = async () => {
    try {
      setIsLoading(true);
      if (userToken) {
        const response = await Axios.get("/get_user_car", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response.status === 200) {
          const data = response?.data;
          // console.log('defalutcar', data.car);
          setDefaultCar(data?.car);
        }
      }
    } catch (err) {
      const error = err?.response?.data;
      // Toast(error.message)
    } finally {
      setIsLoading(false);
    }
  };
  const [allCategoriesData, setAllCategoriesData] = useState([]);

  const getAllCategories = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get("/get_all_categories");
      if (response.status === 200) {
        const data = response?.data;
        setAllCategoriesData(data?.categories.reverse());
        Toast(data?.message, response?.status);
      }
    } catch (err) {
      const error = err?.response?.data;
      Toast(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetCars(userToken);
    getDefaultCar();
    getAllCategories();
  }, [userToken]);
  //console.log("cars", defaultCar.brand);
  // console.log("defaultadd..", defaultCar);

  useEffect(() => {
    if (location.pathname === "/services/detailing") {
      setMetaTitle("Detailing Services - Caross");
      setMetaDescription(metaTitle);
    } else if (location.pathname === "/services/paint-protection") {
      setMetaTitle("Paint Protection  - Caross");
      setMetaDescription(metaTitle);
    } else if (location.pathname === "/services/services") {
      setMetaTitle("Services - Caross");
      setMetaDescription(metaTitle);
    } else if (location.pathname === "/services/accessories") {
      setMetaTitle("Accessories - Caross");
      setMetaDescription(metaTitle);
    } else {
      setMetaTitle("Welcome to caross detailing");
    }
  }, [location.pathname]);

  var settings = {
    infinite: false,
    speed: 500,

    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta
          name="description"
          content="Explore our comprehensive auto detailing services at Caross.in. From exterior paint protection to interior detailing, our skilled professionals ensure your vehicle receives the care it deserves. Elevate your driving experience with our meticulous services."
        ></meta>
        <p>{metaDescription}</p>
      </Helmet>

      {/* {loading && <Loader />} */}
      <div className="container-fluid position-relative mt-2 ">
        <div className="container">
          <div
            className="col-12 d-flex justify-content-center  border-bottom px-3 rounded"
            style={{ backgroundColor: "white" }}
          >
            <div className="category-slider">
              {allCategoriesData.length > 4 ? (
                <Slider {...settings}>
                  {allCategoriesData?.map((logo, idx) => (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      key={idx + 1}
                    >
                      <CategoryCardService
                        logo={logo}
                        SetChanges={SetChanges}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <>
                  <div
                    className="row justify-content-center d-md-flex pb-2 pb-md-1 "
                    style={{ background: "white" }}
                  >
                    {allCategoriesData?.map((logo) => (
                      <CategoryCardService
                        logo={logo}
                        SetChanges={SetChanges}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-md-none d-block col-md-3 position-relative pb-4">
            <div
              className="col-12"
              style={{ visibility: "hidden", height: "72px" }}
            ></div>
            {defaultCar?.brand ? (
              <ChangeCar userCarDetails={defaultCar} />
            ) : null}
          </div>

          <ServiceCard />
        </div>
        {categoriesFQS?.length > 0 && (
          <div className="col-12">
            <FrequentalyAskQuestions faqs={categoriesFQS} />
          </div>
        )}
      </div>
    </>
  );
}

export default Services;
