import React from "react";
import { toast } from "react-toastify";

const Toast = (msg, status) => {
  if (status === 200) {
    return toast.success(msg, {
      position: "top-right",
      // autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    return toast.error(msg, {
      position: "top-right",
      // autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export default Toast;
